import {Directive, ElementRef, HostListener, output} from '@angular/core';

@Directive({
    selector: '[appClickOutside]',
    exportAs: 'appClickOutside',
    standalone: true
})
export class ClickOutsideDirective {
  appClickOutside = output<void>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  onClick(event: PointerEvent): void {
    if (event.target instanceof Element){
      const target = event.target as Element;
      const element = this.elementRef.nativeElement;
      if (element.nodeName === 'DIALOG' && element.localName === 'dialog'){
        if (target.nodeName === 'DIALOG' && target.localName === 'dialog'){
          this.appClickOutside.emit();
        }
        return;
      }
      if (!element.contains(target)) {
        this.appClickOutside.emit();
      }
    }
  }
}
