import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "../services/config/config.service";
import {Company} from "../dto/company-dto";

@Injectable({
  providedIn: 'root'
})
export class ConfigurationApiService {

  private config = this.configService.applicationProperties;

  constructor(private http: HttpClient,
              private configService: ConfigService) {
  }

  getCompaniesWithoutDiagco() {
    return this.http.get<Company[]>(`${this.config.api.configurationApi}/customers?isDiagcoEnabled=false`)
  }


}
