import {Injectable} from '@angular/core';
import {
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  fetchUserAttributes,
  resetPassword,
  signIn,
  signOut
} from 'aws-amplify/auth';
import {Router} from "@angular/router";
import {BehaviorSubject, filter, firstValueFrom} from "rxjs";
import {ConnectedUser, UserGroup} from "../../models/connected-user";
import {ReferenceApiService} from "../../api/reference-api.service";
import * as Sentry from "@sentry/angular";
import {MatomoTracker} from "ngx-matomo-client";
import {StoreService} from "../store.service";
import {ChildCompanyDto, CompanyHierarchyResponseDto} from "../../dto/company-hierarchy-response-dto";
import {Company} from "../../dto/company-dto";
import {ConfigurationApiService} from "../../api/configuration-api.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly _connectedUser = new BehaviorSubject<ConnectedUser | undefined>(undefined);
  private initialized = false;

  readonly connectedUser$ = this._connectedUser.asObservable().pipe(filter(() => this.initialized));

  constructor(private router: Router,
              private tracker: MatomoTracker,
              private referenceApi: ReferenceApiService,
              private store: StoreService,
              private configApi: ConfigurationApiService) {
    this.initializeConnectedUser().then();
  }

  async signIn(username: string, password: string) {
    const signInOutput = await signIn({username, password});
    await this.initializeConnectedUser();
    return signInOutput;
  }

  async signOut() {
    await signOut();
    return this.handleSignOut();
  }

  async sendCode(username: string) {
    await resetPassword({username})
  }

  async resetPassword(username: string, code: string, password: string) {
    await confirmResetPassword({username, confirmationCode: code, newPassword: password})
  }

  async confirmSignIn(newPassword: string) {
    const confirmOutput = await confirmSignIn({challengeResponse: newPassword})
    await this.initializeConnectedUser();
    return confirmOutput;
  }

  getConnectedUser() {
    return this._connectedUser.getValue();
  }

  async getAccessToken() {
    return (await fetchAuthSession()).tokens?.accessToken;
  }

  private async initializeConnectedUser() {
    const connectedUser = await this.getConnectedUserInfo();
    if (connectedUser?.userId) {
      this.setTrackers(connectedUser.userId)
    }
    if (connectedUser?.company){
      connectedUser.hierarchies = await this.processHierarchies(connectedUser);
    }
    this.initialized = true;
    this._connectedUser.next(connectedUser);
  }

  private async getConnectedUserInfo() {
    const [attributes, session] = await Promise.all([fetchUserAttributes().catch(() => undefined), fetchAuthSession()]);
    if (attributes) {
      const groups = session.tokens?.accessToken.payload['cognito:groups'];
      const connectedUser = new ConnectedUser();
      connectedUser.groups = groups ? groups as UserGroup[] : [];
      connectedUser.company = attributes['custom:company'];
      connectedUser.email = attributes.email;
      connectedUser.userId = attributes['custom:user_id'];
      connectedUser.firstName = attributes.given_name;
      connectedUser.lastName = attributes.family_name;
      connectedUser.phone = attributes.phone_number;
      return connectedUser;
    } else {
      return undefined;
    }
  }

  private handleSignOut() {
    this._connectedUser.next(undefined);
    this.store.reset();
    this.resetTrackers();
    this.router.navigate(['auth'], {replaceUrl: true}).then();
  }

  private setTrackers(userId: string) {
    Sentry.setUser({id: userId});
    this.tracker.setUserId(userId);
  }

  private resetTrackers() {
    Sentry.setUser(null)
    this.tracker.resetUserId();
  }

  private getFirstCompanyHierarchy(hierarchy: CompanyHierarchyResponseDto) {
    return this.getFirstLeafChildRecursive(hierarchy.children);
  }

  private getFirstLeafChildRecursive(children: ChildCompanyDto[]): ChildCompanyDto | undefined {
    for (let child of children) {
      if (!child.children || child.children.length === 0) {
        return child;
      } else {
        let leafChild = this.getFirstLeafChildRecursive(child.children);
        if (leafChild) {
          return leafChild;
        }
      }
    }
    return undefined;
  }

  traverseFromLeafToRoot(
    childCompany: CompanyHierarchyResponseDto,
    parentCompany: CompanyHierarchyResponseDto | undefined,
    companiesToDelete: Company[],
    rootCompaniesToDelete: Set<number>
  ) {
    // Recursively call the function for each child
    for (const child of childCompany.children) {
      this.traverseFromLeafToRoot(child as CompanyHierarchyResponseDto, childCompany, companiesToDelete, rootCompaniesToDelete);
    }

    // Check if the current company should be deleted
    if (this.isDiagCoDisabled(childCompany, companiesToDelete)) {
      // If the current company has no children
      if (childCompany.children.length === 0) {
        if (parentCompany) {
          // Remove the current company from its parent's children
          parentCompany.children = parentCompany.children.filter(c => c.id !== childCompany.id);
        } else {
          // If there's no parent, mark it for deletion from the root
          rootCompaniesToDelete.add(childCompany.id);
        }
      }
      // If the parent has no children left after the removal, mark the parent for deletion ( because only leaf nodes are allowed)
      if (parentCompany && parentCompany.children.length === 0) {
        companiesToDelete.push({ name: parentCompany.name, id: parentCompany.key });
      }
    }
  }

  isDiagCoDisabled(company: CompanyHierarchyResponseDto, companiesWithoutDiagCo: Company[]): boolean {
    return companiesWithoutDiagCo.some(companyWithoutDiagCo => companyWithoutDiagCo.id === company.key);
  }

  private async processHierarchies(connectedUser: ConnectedUser) {
    let hierarchies: CompanyHierarchyResponseDto[] = [];
    try {
      if (connectedUser.groups.includes(UserGroup.ADMIN_CLIENT_ADMIN)) {
        hierarchies = (await firstValueFrom(this.referenceApi.getCompanyHierarchies())).hierarchies;
      } else {
        const companyId = (await firstValueFrom(this.referenceApi.getCompany(connectedUser.company!))).id;
        hierarchies.push((await firstValueFrom(this.referenceApi.getCompanyHierarchy(companyId))));
      }
      if (hierarchies.length) {
        let companiesWithoutDiagCo = (await firstValueFrom(this.configApi.getCompaniesWithoutDiagco()));
        const rootCompaniesToDelete = new Set<number>();

        //Traverse from leaf to root for each hierarchy
        for (const hierarchy of hierarchies) {
          this.traverseFromLeafToRoot(hierarchy, undefined, companiesWithoutDiagCo, rootCompaniesToDelete);
        }
        // Remove root companies after traversal
        for (const rootCompanyId of rootCompaniesToDelete) {
          const index = hierarchies.findIndex(c => c.id === rootCompanyId);
          if (index !== -1) {
            hierarchies.splice(index, 1);
          }
        }
        this.storeDefaultSelectedCompany(hierarchies);
      }
    } catch (error) {
      Sentry.captureException("Unable to get company data", {extra: {error}})
    }
    return hierarchies;
  }

  private storeDefaultSelectedCompany(hierarchies : CompanyHierarchyResponseDto[]){
    if (this.store.getSelectedCompanyValue()) {
      return;
    }

    const firstCompanyHierarchy = hierarchies[0].children.length > 0 ?
      this.getFirstCompanyHierarchy(hierarchies[0]) :
      hierarchies[0];

    if (firstCompanyHierarchy) {
      this.store.setSelectedCompany(firstCompanyHierarchy);
    }
  }
}
