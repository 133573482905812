import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  loading$ = new BehaviorSubject<boolean>(false);
  text$ = new BehaviorSubject<string | undefined>(undefined);

  getValue() {
    return this.loading$.asObservable();
  }

  getText() {
    return this.text$.asObservable();
  }

  modifyText(text: string) {
    this.text$.next(text);
  }

  show(text?: string) {
    this.text$.next(text);
    this.loading$.next(true);
  }

  hide() {
    this.text$.next(undefined);
    this.loading$.next(false);
  }
}
