import {Directive, HostListener, Inject, inject} from '@angular/core';
import {MatomoTracker} from "ngx-matomo-client";

@Directive({
  selector: 'button, li',
  standalone: true
})
export class MatomoEventDirective {

  private readonly tracker: MatomoTracker;

  constructor(@Inject(MatomoTracker) tracker: MatomoTracker) {
    this.tracker = tracker;
  }

  @HostListener('click', ['$event.target'])
  onClick(target: HTMLElement) {
    const htmlElement = this.findButtonElement(target);
    if (htmlElement) {
      const { id, innerText } = htmlElement;
      const dataTest = htmlElement.attributes.getNamedItem('data-test')?.value;
      this.tracker.trackEvent('User action', 'Click', id || dataTest || innerText);
    }
  }

  private findButtonElement(element: HTMLElement): HTMLElement | null {

    while (element && (element.tagName !== 'BUTTON' && element.tagName !== 'LI')) {
      element = element.parentElement as HTMLElement;
    }
    return element as HTMLElement;
  }

}
