import {CompanyHierarchyResponseDto} from "../dto/company-hierarchy-response-dto";

export class ConnectedUser {
  firstName?: string;
  lastName?: string;
  groups: UserGroup[] = [];
  userId?: string;
  email?: string | null;
  phone?: string;
  company?: string;
  hierarchies: CompanyHierarchyResponseDto[] = [];
}

export enum UserGroup {
  ADMIN_CLIENT_ADMIN = 'ADMIN_CLIENT_ADMIN'
}
